import CONFIG from "../../../globals/config";

const content1Page = (content1Source) => `
<div class="container">
  <h1 class="text-danger fw-bold text-center">EVENT</h1>
  <p class="text-center text-white">
    Kolaborasi seru bersama komunitas K-Pop! 
    Kami membuka pendaftaran untuk mengikuti 
    berbagai acara menarik. Punya bakat dan minat di bidang K-Pop? 
    Tunjukkan kemampuanmu di atas panggung! Jangan lewatkan kesempatan ini, 
    daftar sekarang dan jadilah bagian dari keluarga K-Pop kami!
  </p>
  <div class="row g-4 mt-4">
    ${content1Source
      .map(
        (source, index) => `
        <div class="col-md-6 col-lg-3" data-aos="fade-up" data-aos-delay="${
          index * 100
        }">
          <div class="card border-0 shadow card-hover">
            <img
              data-src="${source.pictureUrl}"
              class="card-img-top lazyload"
              alt="Image Event ${source.title}"
              style="height: 200px; object-fit: cover;"
            />
            <div class="card-body">
              <h5 class="card-title text-danger">${source.title}</h5>
              <p class="card-text">
                 ${source.date || "Rabu, 01 November 2024"}<br />
                 ${source.startTime || null} - ${source.endTime || null} WIB 
              </p>
              <p class="fw-bold">Price <span class="text-danger">${
                source.price || "75.000"
              }</span></p>
              <a href="#/event/detail/${
                source.id
              }" class="btn btn-danger w-100">Lihat Tiket</a>
            </div>
          </div>
        </div>
        `
      )
      .join("")}
  </div>
</div>
`;
export { content1Page };
