
const content1Page = () => `
  <div class="container py-5">
    <div class="row align-items-center justify-content-center">
      <!-- Form Section -->
      <div class="col-md-6">
        <div class="form-container">
          <h2 class="text-white fw-bold text-center">Masuk <span class="text-danger">IKS CREW!</span></h2>
          <p class="text-center text-white">Silahkan masukkan informasi akunmu</p>
          <form id="login-form" class="mt-4">
            <div class="mb-3">
              <label for="email" class="form-label">Alamat Email</label>
              <input type="email" class="form-control" id="email" placeholder="Alamat Email">
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Kata Sandi</label>
              <div class="input-group">
                <input type="password" class="form-control" id="password" placeholder="Kata Sandi">
                <button type="button" id="toggle-password" class="btn btn-outline-secondary">
                  <i class="bi bi-eye"></i>
                </button>
              </div>
            </div>
            <a href="#/forgot-password" class="text-danger d-block text-end mb-3 forgot-password">Lupa kata sandi?</a>
            <button type="submit" class="btn bg-merah-txt-putih w-100">Masuk Sekarang</button>
          </form>
          <div class="form-footer mt-3">
            <p>Belum memiliki akun? <a href="#/register">Daftar sekarang!</a></p>
          </div>
        </div>
      </div>
      <!-- Illustration Section -->
      <div class="col-md-6 illustration">
        <img class="lazyload" data-src="images/illustration.png" alt="Illustration">
      </div>
    </div>
  </div>
`;

export { content1Page };
