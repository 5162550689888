import CONFIG from "../../../globals/config"; 

const content1Page = () => `
<div class="container mt-5">
  <div class="row gy-4">
    <div
      class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center"
      data-aos="zoom-out"
    >
      <h1>
        Big Dream <br /><span class="textMerah fw-bold">IKS Crew</span>
      </h1>
      <p>
        IKS Crew merupakan platform komunitas yang dilandaskan berdasarkan
        budaya Korea-Indonesia. Kami bertujuan untuk menyatukan bagaimana
        budaya Indonesia dapat berkembang di dunia layaknya Korea yang
        sedang merambah di kanca dunia pada saat ini.
      </p>
    </div>
    <div
      class="col-lg-6 order-1 order-lg-2 hero-img"
      data-aos="zoom-out"
      data-aos-delay="200"
    >
      <img  data-src="images/logo.png" class="img-fluid animated lazyload" alt="" />
    </div>
  </div>
</div>
`;

const content2Page = (content2SourceMp, content2SourceSp) => `
  <div class="container rounded-4 shadow p-5 bg-white" data-aos="fade-up">
    <h2 class="text-center fw-bold text-danger" data-aos="fade-down">Saat Ini</h2>
    <p class="text-center mt-3" data-aos="fade-up" data-aos-delay="200">
      IKS Crew telah berhasil menyukseskan 94% anggota komunitas IKS Crew ke
      dunia Korea. Tidak berhenti sampai sini, kami terus berinovasi dan
      tetap menjaga komitmen kami dalam meningkatkan kualitas pembelajaran
      untuk membantu mereka mendapatkan karier di Korea yang lebih baik.
    </p>
    <div class="row text-center mt-5" data-aos="zoom-in">
      <div class="col-md-4">
        <h3 class="fw-bold text-danger" data-aos="fade-up" data-aos-delay="300">5000+</h3>
        <p>Peserta dalam berbagai program</p>
      </div>
      <div class="col-md-4">
        <h3 class="fw-bold text-danger" data-aos="fade-up" data-aos-delay="400">100+</h3>
        <p>Hiring Partner & Hiring Community</p>
      </div>
      <div class="col-md-4">
        <h3 class="fw-bold text-danger" data-aos="fade-up" data-aos-delay="500">50+</h3>
        <p>Mentor</p>
      </div>
    </div>
    <h3 class="text-center mt-5" data-aos="fade-down">
      Telah <span class="text-danger fw-bold">dipercaya</span> oleh
    </h3>
    <div class="row g-4 mt-4 text-center">
      <h3 class="partner-title" data-aos="fade-right">Media Partner</h3>
      <div class="partner-slider-container" data-aos="fade-up" data-aos-delay="200">
        <div class="partner-slider">
          ${content2SourceMp
            .map(
              (source) => `
            <div class="partner-slide" data-aos="zoom-in">
              <img 
                alt="partner ${source.name}" 
                class="partner-img lazyload" 
                data-src="${source.pictureUrl}" />
            </div>
          `
            )
            .join("")}
          ${content2SourceMp
            .map(
              (source) => `
            <div class="partner-slide" data-aos="zoom-in">
              <img 
                alt="partner ${source.name}" 
                class="partner-img lazyload" 
                data-src="${source.pictureUrl}" />
            </div>
          `
            )
            .join("")}
        </div>
      </div>
      <h3 class="partner-subtitle" data-aos="fade-left">Supported by</h3>
      <div class="partner-slider-container" data-aos="fade-up" data-aos-delay="300">
        <div class="partner-slider partner-slider-reverse">
          ${content2SourceSp
            .map(
              (source) => `
            <div class="partner-slide" data-aos="zoom-in">
              <img 
                alt="supporter ${source.name}" 
                class="partner-img lazyload" 
                data-src="${source.pictureUrl}" />
            </div>
          `
            )
            .join("")}
          ${content2SourceSp
            .map(
              (source) => `
            <div class="partner-slide" data-aos="zoom-in">
              <img 
                alt="supporter ${source.name}" 
                class="partner-img lazyload" 
                data-src="${source.pictureUrl}" />
            </div>
          `
            )
            .join("")}
        </div>
      </div>
    </div>
  </div>
`;

const content3Page = (content3Source) => `
<div class="container my-5">
  <div class="row justify-content-center">
    <div class="text-center mb-4">
      <h1>Our Amazing <span class="text-danger fw-bold">Team</span></h1>
      <p class="lead">Orang hebat dibalik berdirinya IKS Crew</p>
    </div>
    ${content3Source
      .map(
        (source) => `
        <a href="#/profile/detail/${
          source.id
        }" class="col-md-4 mb-4" data-aos="fade-up" data-aos-duration="1000">
          <div class="card text-white border-0">
            <img
              data-src="${source.pictureUrl}"
              class="card-img-top rounded lazyload"
              alt="${source.name}"
              data-aos="zoom-in" data-aos-duration="1500"
            />
            <div class="card-body">
              <div class="cntn-item">
                <div class="posisi" data-aos="fade-right" data-aos-duration="1200">${
                  source.position
                }</div>
                <div class="nama" data-aos="fade-left" data-aos-duration="1200">${
                  source.name
                }</div>
              </div>
              <p class="card-text" data-aos="fade-up" data-aos-duration="1000">
                ${source.visiMisi || "Deskripsi tidak tersedia"}
              </p>
            </div>
          </div>
        </a>`
      )
      .join("")}
  </div>
</div>
`;

const content4Page = () => `
<div class="container">
  <div class="row text-center justify-content-center" data-aos="fade-up" data-aos-duration="1000">
    <div class="col-12 col-md-8 col-lg-7">
      <h1>Contact Us</h1>
      <p class="lead">
        Sampaikan pertanyaan seputar komunitas Kami melalui Email
      </p>
    </div>
  </div>
  <div class="row-70"></div>
  <div class="row">
    <div class="col-12 col-md-8 col-lg m-auto" data-aos="fade-up" data-aos-duration="1200">
      <form id="contactForm">
        <div class="row">
          <div class="col">
            <input
              type="email"
              id="email"
              class="form-control"
              placeholder="Enter email"
              required
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <input
              type="text"
              id="subject"
              class="form-control"
              placeholder="Subject"
              required
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <textarea
              id="message"
              class="form-control"
              name="message"
              rows="3"
              placeholder="How can we help?"
              required
            ></textarea>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <button type="submit" class="btn bg-merah-txt-putih">Submit</button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-12 col-md-8 col-lg pt-5 m-auto pt-lg-0" data-aos="fade-up" data-aos-duration="1000">
      <iframe
        class="mb-4 lazyload"
        data-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.9453651715207!2d106.82481932576351!3d-6.13804271017671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5f709937b8b%3A0xcc24e8c546bb46a1!2sMangga%202%20Square!5e0!3m2!1sid!2sid!4v1731803770389!5m2!1sid!2sid"
        width="100%"
        height="200"
        frameborder="0"
        style="border: 0"
        allowfullscreen=""
      ></iframe>
      <p><strong>Kantor Kami</strong></p>
      <p>
        IKS Crew <br />
        Mangga Dua Square Lantai 2 <br />
        Jl. Mangga Dua No.1, RT.14/RW.08, Mangga Dua, Kota Jakarta Barat,
        <br />
        Daerah Khusus Ibu Kota Jakarta 17136
      </p>
    </div>    
  </div>
</div>
`;


export {
  content1Page,
  content2Page,
  content3Page,
  content4Page,
};
