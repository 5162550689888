import { formatTime } from "../../../utils/formatters";
import { formatCurrency } from "../../../utils/currency";

const editLandingPage = (content1Source) => `
<div class="container mt-4">
  <h2 class="text-center">Edit Banner Landing</h2>
  <div class="d-flex justify-content-between container">
    <button id="backButton" class="btn btn-secondary mt-3">Kembali</button>
    <button id="addButton" class="btn btn-danger mt-3">Tambah</button>
  </div>
  <!-- Form Container -->
  <div id="formContainer" class="d-none mt-4">
    <form id="landingForm" enctype="multipart/form-data">
      <!-- Drag and Drop Image Upload -->
      <div class="mb-3">
        <label for="imageInput" class="form-label">
          Upload Image (Max 5MB, Max 1 Images, .jpg/.jpeg/.png only)
        </label>
        <div id="dropZone" class="drop-zone">
          <p>Drag & drop your image here, or click to select a file</p>
          <input type="file" id="imageInput" accept="image/jpeg, image/png"  hidden />
        </div>
      </div>

      <!-- Title Input -->
      <div class="mb-3">
        <label for="titleInput" class="form-label">Title</label>
        <input type="text" class="form-control" id="titleInput" placeholder="Enter title" required>
      </div>

      <!-- Submit Button -->
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>
  <!-- Cards -->
  <div class="card-container">
  ${
    content1Source.length > 0
      ? content1Source
          .map(
            (items) => `
          <div class="card" >
            <img data-src="${items.pictureUrl}" class="card-img-top lazyload" alt="${items.title}">
            <div class="card-body">
              <h5 class="card-title">${items.title}</h5>
            </div>
            <div class="card-footer d-flex justify-content-between">
              <button class="btn btn-warning update-btn" id="update-button-card" data-id="${items.id}">Update</button>
              <button class="btn btn-danger delete-btn" id="delete-button-card" data-id="${items.id}">Delete</button>
            </div>
          </div>
          `
          )
          .join("")
      : `<p>No content available. Please add new data.</p>`
  }
  </div>
</div>
`;
const editProfilePage = (contentSource) => `
<div class="container mt-4">
<h1 class="text-center">Edit Profile</h1>
  <div class="d-flex justify-content-between container">
    <button id="backButton" class="btn btn-secondary mt-3">Kembali</button>
    <button id="addButton" class="btn btn-danger mt-3">Tambah</button>
  </div>
  <!-- Form Container -->
  <div id="formContainer" class="d-none mt-4">
    <form id="landingForm" enctype="multipart/form-data">
      <!-- Drag and Drop Image Upload -->
      <div class="mb-3">
        <label for="imageInput" class="form-label">
          Upload Image (Max 5MB, Max 1 Images, .jpg/.jpeg/.png only)
        </label>
        <div id="dropZone" class="drop-zone">
          <p>Drag & drop your image here, or click to select a file</p>
          <input type="file" id="imageInput" accept="image/jpeg, image/png"  hidden />
        </div>
      </div>

      <!-- Additional Inputs -->
      <div class="mb-3">
        <label for="nameInput" class="form-label">Name</label>
        <input type="text" class="form-control" id="nameInput" placeholder="Enter name" required>
      </div>

      <div class="mb-3">
        <label for="positionInput" class="form-label">Position</label>
        <input type="text" class="form-control" id="positionInput" placeholder="Enter position" required>
      </div>

      <div class="mb-3">
        <label for="emailInput" class="form-label">Email</label>
        <input type="email" class="form-control" id="emailInput" placeholder="Enter email" required>
      </div>

      <div class="mb-3">
        <label for="visiMisiInput" class="form-label">Visi & Misi</label>
        <textarea class="form-control" id="visiMisiInput" rows="3" placeholder="Enter visi & misi" required></textarea>
      </div>

      <div class="mb-3">
        <label for="aboutInput" class="form-label">About</label>
        <textarea class="form-control" id="aboutInput" rows="3" placeholder="Enter about" required></textarea>
      </div>

      <!-- Submit Button -->
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>
  <!-- Cards -->
  <div class="card-container">
  ${
    contentSource.length > 0
      ? contentSource
          .map(
            (items) => `
          <div class="card" >
            <img data-src="${items.pictureUrl}" class="card-img-top lazyload" alt="${items.name}">
            <div class="card-body">
              <h5 class="card-title">${items.name}</h5>
              <p class="card-text">${items.position}</p>
              <p class="card-text">${items.email}</p>
            </div>
            <div class="card-footer d-flex justify-content-between">
              <button class="btn btn-warning update-btn" id="update-button-card" data-id="${items.id}">Update</button>
              <button class="btn btn-danger delete-btn" id="delete-button-card" data-id="${items.id}">Delete</button>
            </div>
          </div>
          `
          )
          .join("")
      : `<p>No content available. Please add new data.</p>`
  }
  </div>
</div>
`;
const editAktivitasPage = (contentSource) => `
<div class="container mt-4">
<h1 class="text-center">Edit Aktivitas</h1>
  <div class="d-flex justify-content-between container">
    <button id="backButton" class="btn btn-secondary mt-3">Kembali</button>
    <button id="addButton" class="btn btn-danger mt-3">Tambah</button>
  </div>
  <!-- Form Container -->
  <div id="formContainer" class="d-none mt-4">
    <form id="landingForm" enctype="multipart/form-data">
      <!-- Drag and Drop Image Upload -->
      <div class="mb-3">
        <label for="imageInput" class="form-label">
          Upload Image (Max 5MB, Max 10 Images, .jpg/.jpeg/.png only)
        </label>
        <div id="dropZone" class="drop-zone">
          <p>Drag & drop your image here, or click to select a file</p>
          <input type="file" id="imageInput" accept="image/jpeg, image/png" multiple hidden />
        </div>
      </div>

      <!-- Additional Inputs -->
        <div class="mb-3">
          <label for="titleInput" class="form-label">Judul Aktivitas</label>
          <input 
            type="text" 
            class="form-control" 
            id="titleInput" 
            placeholder="Enter Judul Aktivitas" 
             required
          />
        </div>

        <div class="mb-3">
          <label for="deskripsiInput" class="form-label">Deskripsi</label>
          <textarea 
            class="form-control" 
            id="deskripsiInput" 
            rows="3" 
            placeholder="Enter Deskripsi" 
            required
          ></textarea>
        </div>

        <div class="mb-3">
          <label for="tanggalInput" class="form-label">Tanggal</label>
          <input 
            type="date" 
            class="form-control" 
            id="tanggalInput" 
             required
          />
        </div>

        <div class="mb-3">
          <label for="waktuInput" class="form-label">Waktu</label>
          <input 
            type="time" 
            class="form-control" 
            id="waktuInput" 
             required
          />
        </div>

      <!-- Submit Button -->
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>
  <!-- Cards -->
  <div class="card-container">
  ${
    contentSource.length > 0
      ? contentSource
          .map(
            (items) => `
          <div class="card" >
            <img data-src="${items.pictureUrl[0]}" class="card-img-top lazyload" alt="${items.title}">
            <div class="card-body">
              <h5 class="card-title">${items.title}</h5>
            </div>
            <div class="card-footer d-flex justify-content-between">
              <button class="btn btn-warning update-btn" id="update-button-card" data-id="${items.id}">Update</button>
              <button class="btn btn-danger delete-btn" id="delete-button-card" data-id="${items.id}">Delete</button>
            </div>
          </div>
          `
          )
          .join("")
      : `<p>No content available. Please add new data.</p>`
  }
  </div>
</div>
`;
const editMediaPartnerPage = (contentSource) => `
<div class="container mt-4">
<h1 class="text-center">Edit Media Partner</h1>
  <div class="d-flex justify-content-between container">
    <button id="backButton" class="btn btn-secondary mt-3">Kembali</button>
    <button id="addButton" class="btn btn-danger mt-3">Tambah</button>
  </div>
  <!-- Form Container -->
  <div id="formContainer" class="d-none mt-4">
    <form id="landingForm" enctype="multipart/form-data">
      <!-- Drag and Drop Image Upload -->
      <div class="mb-3">
        <label for="imageInput" class="form-label">
          Upload Image (Max 5MB, Max 1 Images, .jpg/.jpeg/.png only)
        </label>
        <div id="dropZone" class="drop-zone">
          <p>Drag & drop your image here, or click to select a file</p>
          <input type="file" id="imageInput" accept="image/jpeg, image/png" multiple hidden />
        </div>
      </div>

      <!-- Additional Inputs -->
        <div class="mb-3">
          <label for="nameInput" class="form-label">name of the media</label>
          <input 
            type="text" 
            class="form-control" 
            id="nameInput" 
            placeholder="Enter name of the media" 
             required
          />
        </div>

        <div class="mb-3">
          <label for="titleInput" class="form-label">Media Aktivitas</label>
          <input 
            type="text" 
            class="form-control" 
            id="titleInput" 
            placeholder="Enter Media Aktivitas" 
             required
          />
        </div>

        <div class="mb-3">
          <label for="deskripsiInput" class="form-label">Deskripsi</label>
          <textarea 
            class="form-control" 
            id="deskripsiInput" 
            rows="3" 
            placeholder="Enter Deskripsi" 
            required
          ></textarea>
        </div>

      <!-- Submit Button -->
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>
  <!-- Cards -->
  <div class="card-container">
  ${
    contentSource.length > 0
      ? contentSource
          .map(
            (items) => `
          <div class="card" >
            <img data-src="${items.pictureUrl}" class="card-img-top lazyload" alt="${items.name}">
            <div class="card-body">
              <h5 class="card-title">${items.name}</h5>
            </div>
            <div class="card-footer d-flex justify-content-between">
              <button class="btn btn-warning update-btn" id="update-button-card" data-id="${items.id}">Update</button>
              <button class="btn btn-danger delete-btn" id="delete-button-card" data-id="${items.id}">Delete</button>
            </div>
          </div>
          `
          )
          .join("")
      : `<p>No content available. Please add new data.</p>`
  }
  </div>
</div>
`;
const editSupportPage = (contentSource) => `
<div class="container mt-4">
<h1 class="text-center">Edit Support</h1>
  <div class="d-flex justify-content-between container">
    <button id="backButton" class="btn btn-secondary mt-3">Kembali</button>
    <button id="addButton" class="btn btn-danger mt-3">Tambah</button>
  </div>
  <!-- Form Container -->
  <div id="formContainer" class="d-none mt-4">
    <form id="landingForm" enctype="multipart/form-data">
      <!-- Drag and Drop Image Upload -->
      <div class="mb-3">
        <label for="imageInput" class="form-label">
          Upload Image (Max 5MB, Max 1 Images, .jpg/.jpeg/.png only)
        </label>
        <div id="dropZone" class="drop-zone">
          <p>Drag & drop your image here, or click to select a file</p>
          <input type="file" id="imageInput" accept="image/jpeg, image/png" multiple hidden />
        </div>
      </div>

      <!-- Additional Inputs -->
        <div class="mb-3">
          <label for="nameInput" class="form-label">name of the support</label>
          <input 
            type="text" 
            class="form-control" 
            id="nameInput" 
            placeholder="Enter name of the support" 
             required
          />
        </div>

        <div class="mb-3">
          <label for="titleInput" class="form-label">Support Aktivitas</label>
          <input 
            type="text" 
            class="form-control" 
            id="titleInput" 
            placeholder="Enter Support Aktivitas" 
             required
          />
        </div>

        <div class="mb-3">
          <label for="deskripsiInput" class="form-label">Deskripsi</label>
          <textarea 
            class="form-control" 
            id="deskripsiInput" 
            rows="3" 
            placeholder="Enter Deskripsi" 
            required
          ></textarea>
        </div>

      <!-- Submit Button -->
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>
  <!-- Cards -->
  <div class="card-container">
  ${
    contentSource.length > 0
      ? contentSource
          .map(
            (items) => `
          <div class="card" >
            <img data-src="${items.pictureUrl}" class="card-img-top lazyload" alt="${items.name}">
            <div class="card-body">
              <h5 class="card-title">${items.name}</h5>
            </div>
            <div class="card-footer d-flex justify-content-between">
              <button class="btn btn-warning update-btn" id="update-button-card" data-id="${items.id}">Update</button>
              <button class="btn btn-danger delete-btn" id="delete-button-card" data-id="${items.id}">Delete</button>
            </div>
          </div>
          `
          )
          .join("")
      : `<p>No content available. Please add new data.</p>`
  }
  </div>
</div>
`;

const editEventPage = (contentSource) => `
<div class="container mt-4">
<h1 class="text-center">Edit Event</h1>
  <div class="d-flex justify-content-between container">
    <button id="backButton" class="btn btn-secondary mt-3">Kembali</button>
    <button id="addButton" class="btn btn-danger mt-3">Tambah</button>
  </div>

  <!-- Form Container -->
  <div id="formContainer" class="d-none mt-4">
    <form id="landingForm" enctype="multipart/form-data">
    
      <!-- Drag and Drop Image Upload -->
      <div class="mb-3">
        <label for="imageInput" class="form-label">
          Upload Image (Max 5MB, Max 1 Images, .jpg/.jpeg/.png only)
        </label>
        <div id="dropZone" class="drop-zone">
          <p>Drag & drop your image here, or click to select a file</p>
          <input type="file" id="imageInput" accept="image/jpeg, image/png" multiple hidden />
        </div>
      </div>

      <!-- Event Title -->
      <div class="mb-3">
        <label for="titleInput" class="form-label">Judul Event</label>
        <input type="text" class="form-control" id="titleInput" placeholder="Enter Judul Event" required/>
      </div>

      <!-- Price and Ticket Inputs -->
      <div class="row mb-3">
        <div class="col-md-6 mb-3">
          <label for="priceInput" class="form-label">Harga Tiket</label>
          <input type="number" class="form-control" id="priceInput" placeholder="Masukkan harga tiket" required/>
        </div>

        <div class="col-md-6 mb-3">
          <label for="ticketsInput" class="form-label">Jumlah Tiket Tersedia</label>
          <input type="number" class="form-control" id="ticketsInput" placeholder="Jumlah tiket yang tersedia" required/>
        </div>

        <!-- Date and Time Inputs -->
        <div class="col-md-4 mb-3">
          <label for="dateInput" class="form-label">Tanggal Event</label>
          <input type="date" class="form-control" id="dateInput" required/>
        </div>

        <div class="col-md-4 mb-3">
          <label for="startTimeInput" class="form-label">Jam Mulai</label>
          <input type="time" class="form-control" id="startTimeInput" required/>
        </div>

        <div class="col-md-4 mb-3">
          <label for="endTimeInput" class="form-label">Jam Selesai</label>
          <input type="time" class="form-control" id="endTimeInput"required />
        </div>
      </div>

      <!-- Venue and Address Inputs -->
      <div class="mb-3">
        <label for="venueInput" class="form-label">Venue</label>
        <input type="text" class="form-control" id="venueInput" placeholder="Lokasi event" required/>
      </div>
      
      <div class="mb-3">
        <label for="addressInput" class="form-label">Alamat</label>
        <input type="text" class="form-control" id="addressInput" placeholder="Alamat lengkap" required/>
      </div>
      
      <!-- Submit Button -->
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>


  <!-- Cards -->
  <div class="card-container">
  ${
    contentSource.length > 0
      ? contentSource
          .map(
            (items) => `
          <div class="card" >
            <img data-src="${
              items.pictureUrl
            }" class="card-img-top lazyload" alt="${items.title}">
            <div class="card-body">
              <h5 class="card-title fw-bold">${items.title}</h5>
              <p class="card-text text-muted">
                <i class="bi bi-calendar-event"></i> ${items.date}
              </p>
              <p class="card-text text-muted">
                <i class="bi bi-clock"></i> 
                ${formatTime(items.startTime)} - ${formatTime(items.endTime)}
              </p>
              <p class="card-text text-success fw-semibold">
                 ${formatCurrency(items.price)}
              </p>
              <p class="card-text text-warning fw-bold">
                STOK: <span class="badge bg-warning text-dark">${
                  items.availableTickets
                }</span>
              </p>
            </div>
            <div class="card-footer d-flex justify-content-between">
              <button class="btn btn-warning update-btn" id="update-button-card" data-id="${
                items.id
              }">Update</button>
              <button class="btn btn-danger delete-btn" id="delete-button-card" data-id="${
                items.id
              }">Delete</button>
            </div>
          </div>
          `
          )
          .join("")
      : `<p>No content available. Please add new data.</p>`
  }
  </div>
</div>
`;

const editPaymentsPage = (payments) => `
<div class="container mt-4">
  <h1 class="text-center">Edit Payments</h1>
  <div class="d-flex justify-content-between container">
    <button id="backButton" class="btn btn-secondary mt-3">Kembali</button>
    <button id="addButton" class="btn btn-danger mt-3">Tambah</button>
  </div>

  <!-- Form Container -->
  <div id="formContainer" class="d-none mt-4">
    <form id="landingForm" enctype="multipart/form-data">
      <!-- Drag and Drop Image Upload -->
      <div class="mb-3">
        <label for="imageInput" class="form-label">
          Upload Image (Max 5MB, Max 1 Images, .jpg/.jpeg/.png only)
        </label>
        <div id="dropZone" class="drop-zone">
          <p>Drag & drop your image here, or click to select a file</p>
          <input type="file" id="imageInput" accept="image/jpeg, image/png" multiple hidden />
        </div>
      </div>

      <!-- Additional Inputs -->
      <div class="mb-3">
        <label for="bankNameInput" class="form-label">Bank Name</label>
        <input 
          type="text" 
          class="form-control" 
          id="bankNameInput" 
          placeholder="Enter Bank Name" 
          required
        />
      </div>

      <div class="mb-3">
        <label for="accountNumberInput" class="form-label">Account Number</label>
        <input 
        type="number" 
        class="form-control" 
        id="accountNumberInput" 
        placeholder="Enter Account Number" required/>
      </div>
      
      <div class="mb-3">
        <label for="accountHolderInput" class="form-label">Account Name</label>
        <input 
        type="text" 
        class="form-control" 
        id="accountHolderInput" 
        placeholder="Enter Account Name" required/>
      </div>

      <!-- Submit Button -->
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>

  <!-- Tabel Data Payments -->
  <div class="mt-4">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>No</th>
          <th>Bank Name</th>
          <th>Account Number</th>
          <th>Account Name</th>
          <th>Picture</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        ${payments
          .map(
            (payment, index) => `
          <tr>
            <td>${index + 1}</td>
            <td>${payment.bankName}</td>
            <td>${payment.accountNumber || "N/A"}</td>
            <td>${payment.accountHolder}</td>
            <td>
              ${
                payment.pictureUrl
                  ? `<img class="lazyload" data-src="${payment.pictureUrl}" alt="Payment Image" style="width: 50px; height: 50px; object-fit: cover;" />`
                  : "No Image"
              }
            </td>
            <td>
              <button class="btn btn-warning btn-sm update-btn" data-id="${
                payment.id
              }">Update</button>
              <button class="btn btn-danger btn-sm delete-btn" data-id="${
                payment.id
              }">Delete</button>
            </td>
          </tr>
        `
          )
          .join("")}
      </tbody>
    </table>
  </div>
</div>
`;

const adminTransaksiPage = (contentSource, status = "") => `
<div class="container mt-4">
  <h1 class="text-center">Transaksi</h1>
  <div class="d-flex justify-content-between container">
    <button id="backButton" class="btn btn-secondary mt-3">Kembali</button>
    <div class="mt-3">
      <select id="filterStatus" class="form-select">
        <option value="" ${
          status === "" ? "selected" : ""
        }>Semua Status</option>
        <option value="pending" ${
          status === "pending" ? "selected" : ""
        }>Pending</option>
        <option value="paid" ${
          status === "paid" ? "selected" : ""
        }>Paid</option>
        <option value="processing" ${
          status === "processing" ? "selected" : ""
        }>Processing</option>
        <option value="success" ${
          status === "success" ? "selected" : ""
        }>Success</option>
        <option value="cancelled" ${
          status === "cancelled" ? "selected" : ""
        }>Cancelled</option>
      </select>
    </div>
  </div>  

  ${
    contentSource.length > 0
      ? contentSource
          .map(
            (items) => `
          <div class="card mt-4">
            <div class="card-header">
              <div class="d-flex justify-content-between align-items-center">
                <h4>${items.user.username}</h4>
                <span>Status: ${items.status}</span>
              </div>
            </div>
            <div class="card-body">
              ${items.events
                .map(
                  (event) => `
                  <div class="d-flex justify-content-between align-items-center">
                    <h5 class="card-title">${event.title}</h5>
                    <span>Harga Ticket : ${formatCurrency(event.price)}</span>
                  </div>
                  <span>Total Ticket : x${items.quantity}</span>
                `
                )
                .join("")}
            </div>
            <div class="card-footer text-muted">
              <div class="d-flex justify-content-between">
                <h5 class="fw-bold mb-3">Total Pesanan: ${
                  items.totalAmount
                }</h5>
                <div class="d-flex gap-2">
                  ${
                    items.status !== "cancelled" && items.status !== "success"
                      ? `
                        <a data-id="${items.id}" data-status="${items.status}" class="btn btn-danger cancel-btn">Cancelled</a>
                      `
                      : ""
                  }
                  ${
                    items.status === "paid"
                      ? `
                        <a data-id="${items.id}" data-status="paid" class="btn btn-info update-btn">Proses Pesanan</a>
                      `
                      : ""
                  }
                  ${
                    items.status === "processing"
                      ? `
                        <a data-id="${items.id}" data-status="processing" class="btn btn-primary update-btn">Selesaikan Pesanan</a>
                      `
                      : ""
                  }
                  ${
                    [
                      "pending",
                      "paid",
                      "processing",
                      "success",
                      "cancelled",
                    ].includes(items.status)
                      ? `
                        <a href="#/order/detail/${items.id}" data-id="${items.id}" target="_blank" class="btn btn-primary">Lihat Order</a>
                        <a href="https://wa.me/${items.phoneNumber}" target="_blank" class="btn btn-success"><i class="bi bi-whatsapp"></i> Hubungi Pembeli</a>
                      `
                      : ""
                  }
                </div>
              </div>
            </div>
          </div>
          `
          )
          .join("")
      : `
      <div class="text-center mt-5">
        <h3>Status not found</h3>
        <p>Tidak ada transaksi dengan status "${status || "Semua Status"}"</p>
      </div>
    `
  }
</div>
`;

const userTransaksiPage = (contentSource, status = "") => `
<div class="container mt-4">
  <h1 class="text-center">Transaksi</h1>
  <div class="d-flex justify-content-between container">
    <button id="backButton" class="btn btn-secondary mt-3">Kembali</button>
    <div class="mt-3">
      <select id="filterStatus" class="form-select">
        <option value="" ${
          status === "" ? "selected" : ""
        }>Semua Status</option>
        <option value="pending" ${
          status === "pending" ? "selected" : ""
        }>Pending</option>
        <option value="paid" ${
          status === "paid" ? "selected" : ""
        }>Paid</option>
        <option value="processing" ${
          status === "processing" ? "selected" : ""
        }>Processing</option>
        <option value="success" ${
          status === "success" ? "selected" : ""
        }>Success</option>
        <option value="cancelled" ${
          status === "cancelled" ? "selected" : ""
        }>Cancelled</option>
      </select>
    </div>
  </div>  

  ${
    contentSource.length > 0
      ? contentSource
          .map(
            (items) => `
          <div class="card mt-4">
            <div class="card-header">
              <div class="d-flex justify-content-end">
                <span>Status: ${items.status}</span>
              </div>
            </div>
            <div class="card-body">
              ${items.events
                .map(
                  (event) => `
                  <div class="d-flex justify-content-between align-items-center">
                    <h5 class="card-title">${event.title}</h5>
                    <span>Harga Ticket : ${formatCurrency(event.price)}</span>
                  </div>
                  <span>Total Ticket : x${items.quantity}</span>
                `
                )
                .join("")}
            </div>

            <div class="card-footer text-muted">
              <div class="d-flex justify-content-between">
                <h5 class="fw-bold mb-3">Total Pesanan: ${
                  items.totalAmount
                }</h5>
                <div class="d-flex gap-2">
                  
                  ${
                    items.status === "pending"
                      ? `
                        <a data-id="${items.id}" data-status="pending" class="btn btn-success update-btn">Konfirmasi Pembayaran</a>
                        <a data-id="${items.id}" data-status="pending" class="btn btn-danger cancel-btn">Cancel Order</a>
                        <a href="#/order/detail/${items.id}" data-id="${items.id}" target="_blank" class="btn btn-primary">Lihat Order</a>
                      `
                      : `
                        <a href="#/order/detail/${items.id}" data-id="${items.id}" target="_blank" class="btn btn-primary">Lihat Order</a>
                      `
                  }
                </div>
              </div>
            </div>
          </div>
          `
          )
          .join("")
      : `
      <div class="text-center mt-5">
        <h3>Status not found</h3>
        <p>Tidak ada transaksi dengan status "${status || "Semua Status"}"</p>
      </div>
    `
  }
</div>
`;

const ProfileUsersPage = () => `
<div class="container mt-4">
  <h1 class="text-center">Profile Users</h1>
  <div class="d-flex justify-content-between container">
    <button id="backButton" class="btn btn-secondary mt-3">Kembali</button>
    <button id="addButton" class="btn btn-danger mt-3">Tambah</button>
  </div>
</div>
`;

export {
  editLandingPage,
  editProfilePage,
  editAktivitasPage,
  editMediaPartnerPage,
  editSupportPage,
  editEventPage,
  editPaymentsPage,
  adminTransaksiPage,
  userTransaksiPage,
  ProfileUsersPage,
};
