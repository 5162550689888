const checkoutPage = (eventDetails) => `
  <div class="container mt-5 text-light">
    <h1 class="text-warning text-center mb-4">Halaman Checkout</h1>

    <!-- Detail Event yang Dibeli -->
    <div class="row mb-4">
      <div class="col-12">
        <h4 class="text-warning">Detail Acara yang Dibeli</h4>
        ${eventDetails
          .map(
            (event) => `
          <div class="card mb-3 bg-dark text-light">
            <div class="row no-gutters">
              <div class="col-md-4">
                <img data-src="${
                  event.pictureUrl
                }" class="card-img lazyload" alt="${event.title}">
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title text-warning">${event.title}</h5>
                  <p class="card-text text-info">Tanggal: ${new Date(
                    event.date
                  ).toLocaleDateString()}</p>
                  <p class="card-text text-info">Waktu: ${event.startTime} - ${
              event.endTime
            }</p>
                  <p class="card-text text-info">Jumlah Tiket: ${
                    event.quantity
                  }</p>
                  <p class="card-text text-warning">Total Harga: Rp${(
                    event.price * event.quantity
                  ).toLocaleString("id-ID")}</p>
                </div>
              </div>
            </div>
          </div>
        `
          )
          .join("")}
      </div>
    </div>

    <!-- Formulir Checkout -->
    <div class="row">
      <div class="col-12">
        <h4 class="text-warning">Informasi Pembeli</h4>
        <form id="checkout-form">
          <div class="mb-3">
            <label for="customerName" class="form-label text-warning">Nama Lengkap</label>
            <input type="text" class="form-control" id="customerName" placeholder="Masukkan nama lengkap" required>
          </div>

          <div class="mb-3">
            <label for="email" class="form-label text-warning">Email</label>
            <input type="email" class="form-control" id="email" placeholder="Masukkan email" required>
          </div>

          <div class="mb-3">
            <label for="phoneNumber" class="form-label text-warning">Nomor Telepon</label>
            <input type="text" class="form-control" id="phoneNumber" placeholder="Masukkan nomor telepon" required>
          </div>

          <div class="mb-3">
            <label for="paymentMethod" class="form-label text-warning">Metode Pembayaran</label>
            <select class="form-select" id="paymentMethod" required>
              <option value="ujang">Ujang</option>
              <option value="gopay">GoPay</option>
              <option value="ovo">OVO</option>
            </select>
          </div>

          <button type="submit" class="btn btn-warning btn-lg w-100">Proses Pembayaran</button>
        </form>
      </div>
    </div>
  </div>
`;

export { checkoutPage };
