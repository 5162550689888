const content1Page = () => `
  <div class="container py-5">
    <div class="row align-items-center justify-content-center">
      <!-- Form Section -->
      <div class="col-md-6">
        <div class="form-container">
          <h2 class="text-white fw-bold text-center">Daftar menjadi <span class=" text-danger ">IKS CREW!</span></h2>
          <form class="mt-4" id="register-form">
            <div class="mb-3">
              <label for="username" class="form-label">Full Name</label>
              <input type="text" class="form-control" id="username" placeholder="Full Name">
            </div>
            <div class="mb-3">
              <label for="email" class="form-label">Alamat Email</label>
              <input type="email" class="form-control" id="email" placeholder="Alamat Email">
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Kata Sandi</label> 
              <div class="input-group">
                <input type="password" class="form-control" id="password" placeholder="Kata Sandi">
                <button type="button" class="btn btn-outline-secondary" id="toggle-password"><i class="bi bi-eye"></i></button>
              </div>
            </div>
            <div class="mb-3">
              <label for="confirm-password" class="form-label">Konfirmasi Kata Sandi</label>
              <div class="input-group">
                <input type="password" class="form-control" id="confirm-password" placeholder="Konfirmasi Kata Sandi">
                <button type="button" class="btn btn-outline-secondary" id="toggle-confirm-password"><i class="bi bi-eye"></i></button>
              </div>
            </div>
            <button type="submit" class="btn bg-merah-txt-putih w-100">Daftar Sekarang</button>
          </form>
          <div class="form-footer mt-3">
            <p>Sudah memiliki akun? <a href="#/login">Masuk sekarang!</a></p>
          </div>
        </div>
      </div>
      <!-- Illustration Section -->
      <div class="col-md-6 illustration">
        <img class="lazyload" data-src="images/illustration.png" alt="Illustration">
      </div>
    </div>
  </div>
`;

export { content1Page };
