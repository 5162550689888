const dashboardSidebar = (role) => {
  const adminItems = `
    <li class="nav-item">
      <a href="#/admin/editlanding" class="nav-link text-white">Edit Landing</a>
    </li>
    <li class="nav-item">
      <a href="#/admin/editprofile" class="nav-link text-white">Edit Profile</a>
    </li>
    <li class="nav-item">
      <a href="#/admin/editaktivitas" class="nav-link text-white">Edit Aktivitas</a>
    </li>
    <li class="nav-item">
      <a href="#/admin/editmedia-partner" class="nav-link text-white">Edit Media Partner</a>
    </li>
    <li class="nav-item">
      <a href="#/admin/editsupport" class="nav-link text-white">Edit Support</a>
    </li>
    <li class="nav-item">
      <a href="#/admin/editevent" class="nav-link text-white">Edit Event</a>
    </li>
    <li class="nav-item">
      <a href="#/admin/editpayments" class="nav-link text-white">Edit Payments</a>
    </li>
    <li class="nav-item">
      <a href="#/admin/transaksi" class="nav-link text-white">Transaksi</a>
    </li>
  `;

  const userItems = `
    <li class="nav-item">
      <a href="#/user/transaksi" class="nav-link text-white">Transaksi</a>
    </li>
  `;

  return `
    <div class="bg-dark text-white p-3 sidebar">
      <ul class="nav flex-column">
        ${role === "admin" ? adminItems : userItems}
        <li class="nav-item">
          <button id="logoutBtn" class="btn btn-danger text-white">Logout</button>
        </li>
      </ul>
    </div>
  `;
};

const dashboardUserPage = () => `
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3" id="sidebar-container"></div>
      <div class="col-md-9">
        <div class="content p-4">
          <h2 class=" text-center">Dashboard User</h2>
          <p >Welcome to your user dashboard. Here you can view your profile and transactions.</p>
        </div>
      </div>
    </div>
  </div>
`;

// Dashboard Admin
const dashboardAdminPage = () => `
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3" id="sidebar-container"></div>
      <div class="col-md-9">
        <div class="content p-4">
          <h2 class=" text-center">Dashboard Admin</h2>
          <div class="d-flex gap-3 mb-4">
            <!-- Count box -->
            <div id="user-count-box" class="bg-info text-white p-3 rounded">
              <h3>Total Users</h3>
              <h3 class=" text-center" id="user-count">Loading...</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
`;

export { dashboardSidebar, dashboardUserPage, dashboardAdminPage };
