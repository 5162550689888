const content1Page = () => `
  <div class="container py-5">
    <div class="row align-items-center justify-content-center">
      <!-- Form Section -->
      <div class="col-md-6">
        <div class="form-container">
          <h2 class="text-white fw-bold text-center">Lupa <span class="text-danger">Kata Sandi?</span></h2>
          <p class="text-center text-white">Masukkan alamat email kamu untuk mengatur ulang kata sandi</p>
          <form class="mt-4">
            <div class="mb-3">
              <label for="email" class="form-label">Alamat Email</label>
              <input type="email" class="form-control" id="email" placeholder="Alamat Email">
            </div>
            <button type="submit" class="btn bg-merah-txt-putih w-100">Kirim Link Pemulihan</button>
          </form>
          <div class="form-footer mt-3">
            <p>Ingat kembali kata sandi? <a href="#/login">Masuk sekarang!</a></p>
          </div>
        </div>
      </div>
      <!-- Illustration Section -->
      <div class="col-md-6 illustration">
        <img class="lazyload" data-src="images/illustration.png" alt="Illustration">
      </div>
    </div>
  </div>
`;

export { content1Page };
