import CONFIG from "../../../globals/config";

const content1Page = () => `
<div class="container">
  <div class="row justify-content-center">
    <div class="col col-md-8 text-center" data-aos="zoom-out">
      <h1><strong >Annyeonghaseyo, <span class="textMerah">안녕하세요!</span></strong></h1>
      <p class="lead" data-aos="zoom-out" data-aos-delay="200">
          Selamat datang! Bergabunglah dengan
          <span class="textMerah">IKS Crew</span>.<br />
          Komunitas korean style seru dan kekinian yang selalu mengikuti tren.
          Di sini, kamu bisa berkarya, berkreasi, dan jadi bagian dari
          komunitas kami. Ayo, jadi bagian dari perjalanan seru bersama
          <span class="textMerah">IKS!</span>
        </p>
      </div>
  </div>
</div>
`;

const content2Page = (content2Source) => {
  const createIndicators = () => {
    return content2Source
      .map(
        (_, index) => `
      <button type="button" 
        data-bs-target="#myCarousel" 
        data-bs-slide-to="${index}" 
        ${index === 0 ? 'class="active" aria-current="true"' : ""} 
        aria-label="Slide ${index + 1}">
      </button>
    `
      )
      .join("");
  };

  // Buat carousel items berdasarkan data
  const createCarouselItems = () => {
    return content2Source
      .map(
        (content2Source, index) => `
      <div class="carousel-item ${index === 0 ? "active" : ""}">
        <img 
          data-src="${content2Source.pictureUrl}" 
          class="d-block w-100 lazyload" 
          alt="${content2Source.title}">
      </div>
    `
      )
      .join("");
  };

  return `
    <div class="container mb-5">
      <div class="row">
        <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            ${createIndicators()}
          </div>
          <div class="carousel-inner">
            ${createCarouselItems()}
          </div>
        </div>
      </div>
    </div>
  `;
};

const content3Page = (content3Source) => {
  return content3Source
    .map((item, index) => {
      // Layout bergantian berdasarkan indeks
      if (index % 2 === 0) {
        // Gambar di kiri
        return `
<div class="container py-0 mt-5" data-aos="fade-up" data-aos-duration="1000">
  <div class="container-fluid px-0">
    <div class="row justify-content-between align-items-center g-0">
      <div class="col-md-6 d-flex justify-content-center">
        <img class="lazyload" data-src="${
          item.pictureUrl[0]
        }" style="width:100%;max-height:500px; object-fit: cover;">
      </div>
      <div class="col-md-6 text-center py-5 px-4 px-md-5">
        <h1 class="textMerah">${item.title}</h1>
        <p class="lead content-deskripsi">${
          item.description || "Deskripsi tidak tersedia"
        }</p>
        <p class="mt-4">
          <a class="btn bg-merah-txt-putih" href="#/aktivitas/detail/${
            item.id
          }">Show More</a>
        </p>
      </div>
    </div>
  </div>
</div>
`;
      } else {
        // Gambar di kanan
        return `
<div class="container py-0 mt-5" data-aos="fade-up" data-aos-duration="1000">
  <div class="container-fluid px-0">
    <div class="row justify-content-between align-items-center g-0">
      <div class="col-md-6 d-flex justify-content-center order-first order-md-last">
        <img class="lazyload" data-src="${
          item.pictureUrl[0]
        }" style="width:100%;max-height:600px; object-fit: cover;">
      </div>
      <div class="col-md-6 text-center py-5 px-4 px-md-5">
        <h1 class="textMerah">${item.title}</h1>
        <p class="lead content-deskripsi">${
          item.description || "Deskripsi tidak tersedia"
        }</p>
        <p class="mt-4 mb-5">
          <a class="btn bg-merah-txt-putih" href="#/aktivitas/detail/${
            item.id
          }">Show More</a>
        </p>
      </div>
    </div>
  </div>
</div>
`;
      }
    })
    .join(""); // Gabungkan semua elemen menjadi satu string
};

const content4Page = () => `
<div class="container align-items-center justify-content-center d-flex mt-5 position-relative">
  <img 
    alt="image" 
    class="img-fluid img-fixed-size w-100 lazyload" 
    data-src="images/Rectangle_7.png" 
    data-aos="fade-up" 
    data-aos-duration="1000" 
  >
  <div 
    class="image-text-container position-absolute top-50 start-50 translate-middle text-center" 
    data-aos="zoom-in" 
    data-aos-delay="300" 
  >
    <div class="image-text">
      THE WORLD'S LARGEST KPOP CONCERT EVENT SPANNING ALL COUNTRIES WITH DOZENS OF GROUPS FROM ALL AGENCIES OF THE KPOP INDUSTRY THROUGH SOUTH KOREA
    </div>
  </div>
</div>
`;

const content5Page = (content5Source) => `
  <div class="container" data-aos="fade-up" data-aos-duration="1000">
    <h2 
      class="partner-subtitle" 
      data-aos="fade-right" 
      data-aos-duration="1200"
    >
      Bersama Media Partner dari Berbagai Komunitas
    </h2>
    
    <div class="partner-slider-container" data-aos="fade-up" data-aos-delay="200">
      <div class="partner-slider">
        <!-- Menggunakan data asli -->
        ${content5Source
          .map(
            (source, index) => `
          <div class="partner-slide" data-aos="zoom-in" data-aos-delay="${
            index * 100
          }">
            <img 
              alt="partner ${source.title}" 
              class="partner-img lazyload" 
              data-src="${source.pictureUrl}" />
          </div>
        `
          )
          .join("")}
        <!-- Duplikasi untuk seamless loop -->
        ${content5Source
          .map(
            (source, index) => `
          <div class="partner-slide" data-aos="zoom-in" data-aos-delay="${
            index * 100
          }">
            <img 
              alt="partner ${source.name}" 
              class="partner-img lazyload" 
              data-src="${source.pictureUrl}" />
          </div>
        `
          )
          .join("")}
      </div>
    </div>
  </div>
`;

const content6Page = (content6Source) => `
  <div class="container" data-aos="fade-up" data-aos-duration="1000">
    <h3 
      class="partner-subtitle" 
      data-aos="fade-right" 
      data-aos-duration="1200"
    >
      Supported by
    </h3>
    <div 
      class="partner-slider-container" 
      data-aos="fade-up" 
      data-aos-delay="200"
    >
      <div class="partner-slider partner-slider-reverse">
        <!-- Menggunakan data asli -->
        ${content6Source
          .map(
            (source, index) => `
          <div class="partner-slide" data-aos="zoom-in" data-aos-delay="${
            index * 100
          }">
            <img 
              alt="supporter ${source.name}" 
              class="partner-img lazyload" 
              data-src="${source.pictureUrl}" />
          </div>
        `
          )
          .join("")}
        <!-- Duplikasi untuk seamless loop -->
        ${content6Source
          .map(
            (source, index) => `
          <div class="partner-slide" data-aos="zoom-in" data-aos-delay="${
            index * 100
          }">
            <img 
              alt="supporter ${source.name}" 
              class="partner-img lazyload" 
              data-src="${source.pictureUrl}" />
          </div>
        `
          )
          .join("")}
      </div>
    </div>
  </div>
`;

const content7Page = () => `
<div 
  class="container position-relative text-center text-white" 
  data-aos="fade-in" 
  data-aos-duration="1200"
>
  <img
    data-src="images/Rectangle_30.png"
    alt="Event Background"
    class="img-fluid w-100 lazyload"
    style="height: 400px; object-fit: cover; filter: brightness(70%)"
    data-aos="zoom-out"
    data-aos-duration="1000"
  />
  <div
    class="content7-overlay position-absolute top-50 start-50 translate-middle"
    data-aos="fade-up"
    data-aos-delay="300"
  >
    <h2 class="fw-bold">GET YOUR TICKET NOW</h2>
    <a href="#/event" class="btn bg-putih-textMerah btn-lg mt-3">Buy Now</a>
  </div>
</div>
`;

export {
  content1Page,
  content2Page,
  content3Page,
  content4Page,
  content5Page,
  content6Page,
  content7Page,
};
